@import "../../styles/default_import";

.card {
    color: $black;
    background-color: $lightGrey2;
    display: block;
    cursor: pointer;

    // width: 180px;
    // width: calc(100% - $default-padding-half);

    border-radius: 0 20px 0 0;
    padding: $default-padding;
    margin-bottom: $default-padding-half;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow $mediumDuration linear;

    &:hover {
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    }
    &:active {
        opacity: 0.5;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
        transition: all 0.1s ease;
    }

    img {
        width: 100%;
        //     &.ghost-place-holder {
        //         background-color: $darkGrey;
        //         width: 100%;
        //         height: auto;
        //         min-height: 100px; // not sure how to calculate this height?
        //         transition: height $fastDuration linear;
        //     }
    }

    .card-footer {
        padding-top: $default-padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .card-footer__no-img {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .card-header {
        display: none;
    }

    .card-info {
        h2 {
            margin: 0 0 5px 0;
        }
        h3 {
            margin: 0 0 2px 0;
            line-height: 16px;
            font-family: $lightBody;
        }
        .capacity {
            font-size: $smFont;
            letter-spacing: 0;
            line-height: 20px;
            font-family: $mediumBody;
            padding-left: 13px;
            display: block;
            background: url(../../images/attendees-black.svg) no-repeat left 5px;
            background-size: 9px 9px;
        }
    }
    .price-button-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;

        .price {
            margin-bottom: 10px;
            display: block;
            font-size: $mdFont;
            letter-spacing: 0;
            line-height: 13px;
        }
        .button {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@include respond-above(xsm) {
    .card {
        .card-header {
            display: block;
            margin-bottom: 5px;
        }
        .card-footer {
            justify-content: flex-end;
            .card-info {
                display: none;
            }
        }
        .card-info {
            h2 {
                margin-bottom: 0;
            }
            h3 {
                margin: 0 0 5px 0;
            }
            .capacity {
                font-size: $xsFont;
                line-height: 16px;
                padding-left: 12px;
                background: url(../../images/attendees-black.svg) no-repeat left 4px;
                background-size: 8px 8px;
            }
        }
        .price-button-container {
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .price {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }
    }
}
