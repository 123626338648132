@import "../../styles/default_import";

.checkout-header {
    margin: 0;
    padding: $default-padding 0;
    background-color: var(--booker25-company-primary);
    color: $white;
    border-radius: 20px 0 0 0;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    .back-and-header {
        margin-bottom: 15px;
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .back-button {
            font-size: 0;
            display: block;
            cursor: pointer;
            height: 20px;
            width: 20px;
            background: url(../../images/back-white.svg) no-repeat center;
            background-size: 20px 20px;
            transition: all 0.4s ease;
            &:hover {
                opacity: 0.5;
            }
            &:active {
                opacity: 0.2;
                transition: all 0.1s ease;
            }
        }
        h2 {
            margin: 0 0 0 20px;
        }
    }
    .progress-stepper {
        margin-left: 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        .individual-step {
            padding: 15px 0 0 0;
            font-size: $smFont;
            line-height: $smFont;
            text-align: center;
            position: relative;
            opacity: 0.3;
            .dot {
                width: 8px;
                height: 8px;
                background-color: var(--booker25-company-primary);
                box-sizing: border-box;
                display: block;
                border-radius: 4px;
                border: 1px solid $white;
                position: absolute;
                left: 50%;
                top: 0;
                z-index: 1;
                margin: 0 0 0 -4px;
            }
            &:before {
                content: "";
                height: 1px;
                width: 50%;
                background-color: $lightGrey2;
                position: absolute;
                top: 4px;
                left: 0%;
                z-index: 0;
            }
            &:after {
                content: "";
                height: 1px;
                width: 50%;
                background-color: $lightGrey2;
                position: absolute;
                top: 4px;
                left: 50%;
                z-index: 0;
            }
            &:first-of-type {
                &:before {
                    display: none;
                }
            }
            &:last-of-type {
                &:after {
                    display: none;
                }
            }
        }
        @for $i from 1 through 5 {
            &.total-steps-#{$i} {
                .individual-step {
                    width: #{100% / $i}
                }
            }
        }
        &.step-1 {
            .individual-step {
                &:first-of-type {
                    opacity: 1;
                    .dot {
                        background-color: $white;
                    }
                    &:after {
                        opacity: 0.3;
                    }
                }
            }
        }
        &.step-2 {
            .individual-step {
                &:first-of-type,
                &:nth-of-type(2) {
                    opacity: 1;
                    .dot {
                        background-color: $white;
                    }
                }
                &:nth-of-type(2) {
                    &:after {
                        opacity: 0.3;
                    }
                }
            }
        }
        &.step-3 {
            .individual-step {
                &:first-of-type,
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    opacity: 1;
                    .dot {
                        background-color: $white;
                    }
                }
                &:nth-of-type(3) {
                    &:after {
                        opacity: 0.3;
                    }
                }
            }
        }
        &.step-4 {
            .individual-step {
                &:first-of-type,
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    opacity: 1;
                    .dot {
                        background-color: $white;
                    }
                }
                &:nth-of-type(4) {
                    &:after {
                        opacity: 0.3;
                    }
                }
            }
        }
        &.step-5 {
            .individual-step {
                &:first-of-type,
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:last-of-type {
                    opacity: 1;
                    .dot {
                        background-color: $white;
                    }
                }
                &:last-of-type {
                    &:after {
                        opacity: 0.3;
                    }
                }
            }
        }
    }
}

@include respond-above(xsm) {
    .checkout-header {
        .progress-stepper {
            .individual-step {
                font-size: $xsFont;
                line-height: $xsFont;
            }
        }
    }
}
