// Media
// Image responsive
.responsive-image,
.img-responsive {
    display: block;
    height: auto;
    max-width: 100%;
}

// object-fit support is coming to Microsoft Edge
// https://developer.microsoft.com/en-us/microsoft-edge/platform/status/objectfitandobjectposition/
.img-fit-cover {
    object-fit: cover;
}

.img-fit-contain {
    object-fit: contain;
}

.image {
    position: relative;
    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    &.is-square,
    &.is-1by1 {
        padding-bottom: 100%;
    }
    &.is-5by4 {
        padding-bottom: 80%;
    }
    &.is-4by3 {
        padding-bottom: 75%;
    }
    &.is-3by2 {
        padding-bottom: 66.6666%;
    }
    &.is-5by3 {
        padding-bottom: 60%;
    }
    &.is-16by9 {
        padding-bottom: 56.25%;
    }
    &.is-2by1 {
        padding-bottom: 50%;
    }
    &.is-3by1 {
        padding-bottom: 33.3333%;
    }
    &.is-4by5 {
        padding-bottom: 125%;
    }
    &.is-3by4 {
        padding-bottom: 133.3333%;
    }
    &.is-2by3 {
        padding-bottom: 150%;
    }
    &.is-3by5 {
        padding-bottom: 166.6666%;
    }
    &.is-9by16 {
        padding-bottom: 177.7777%;
    }
    &.is-1by2 {
        padding-bottom: 200%;
    }
    &.is-1by3 {
        padding-bottom: 300%;
    }
}

// https://github.com/jgthms/bulma/blob/eb28457c3cea9f188f62836a8b6dd94169aea480/sass/elements/image.sass

/**
* CSS image crop. Forces image to 4:3 aspect ratio cropping at the middle.
* http://dabblet.com/gist/4711695
*/
.img-crop,
.img-crop img {
    width: 100%; /* gotta be responsive these days */
}
.img-crop {
    background-color: #ededed; /* some background color for short images */
    overflow: hidden;

    /* remove white-space for inline-block elements */
    letter-spacing: -0.31em; /* webkit */
    *letter-spacing: normal; /* reset IE < 8 */
    word-spacing: -0.43em; /* IE < 8 && gecko */
}
.img-crop:before,
.img-crop img {
    /* vertically center the image */
    display: inline-block;
    vertical-align: middle;
}
.img-crop:before {
    /* pseudo element to vertically align image to */
    content: "";

    /* Magic Number */
    /* Set this to your desired height(to width) ratio */
    // padding-top: 75%; /* % of original image width */
    padding-top: 50%; /* % of original image width */
}
.img-crop img {
    /* removes(sorta) image from the flow */
    padding-left: 100%;
    margin: -999em -100%;
}
