// Primary colors
$black: #000000;
$darkGrey: #8d8d8d;
$midGrey: #e6e6e6;
$lightGrey1: #f2f2f2;
$lightGrey2: #f7f7f7;
$white: #ffffff;

$error: #ff0000;

// debug
$pink: #ffb5f5;

/* Backgrounds */
.bg {
  &-primary {
    background-color: var(--booker25-company-primary);
    color: $white;
  }
  &-white {
    background-color: $white;
    color: $black;
  }
  &-black {
    background-color: $black;
    color: $white;
  }
  &-pink {
    background-color: $pink;
    color: $black;
  }
  &-error,
  &-red {
    background-color: $error;
    color: $white;
  }
  &-darkgray {
    background-color: $darkGrey;
    color: $black;
  }
  &-midgray {
    background-color: $midGrey;
    color: $black;
  }
  &-lightgray1 {
    background-color: $lightGrey1;
    color: $black;
  }
  &-lightgray2 {
    background-color: $lightGrey2;
    color: $black;
  }
  &-softred {
    background-color: var(--booker25-company-primary);
    color: $black;
  }
}

$grayz: #f0f0f0;

// bg gray/pink colors
@for $i from 1 through 10 {
  .bg-gray-#{$i*100} {
    background-color: darken($grayz, $i * 7);
  }
  .bg-pink-#{$i*100} {
    background-color: darken($pink, $i * 7);
  }
}

/* Fills for SVG */
.fill-primary {
  fill: var(--booker25-company-primary);
}
.fill-white {
  fill: $white;
}
.fill-black {
  fill: $black;
}
