@import "../../styles/_default_import.scss";

.time-container {
    border-radius: 20px;
    background-color: $lightGrey2;
    overflow: auto;
    max-height: 480px;

    .loading-state {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .spinner {
            width: 30px;
            height: 30px;
            margin-bottom: 5px;
            background: url(../../images/loading-spinner.svg) no-repeat center;
            background-size: 30px 30px;
        }
        .loading-text
         {   margin: 0;}
    }

    .no-slots-available {
        padding: 20px;
        text-align: center;

        p
            {margin: 0;}
    }

    .container-available-time-slots {
        border-bottom: 1px solid $midGrey;
        padding-top: 10px;

        h4 {
            font-family: $mediumBody;
            font-size: 9px;
            width: 50px;
            padding: 0;
            margin: 0;
            padding-left: $default-padding;
            padding-top: 5px;
            float: left;
        }

        .align-right {
            margin: 0;
            padding: 0;
            max-width: 250px;
            margin-left: auto;
            margin-right: 0;

            .available-time-slots {
                padding-top: 0px;
                padding-right: $default-padding;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;

                li {
                    box-sizing: border-box;
                    padding: 5px;
                    width: 50%;
                    white-space: nowrap;

                    .button--secondary {
                        &:hover
                     {       opacity: 1;}
                    }
                }
            }
        }
    }

    .available-time-slots {
        max-width: 280px;
        margin: 0 auto;
        padding: 20px 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;

        li {
            box-sizing: border-box;
            padding: 5px;
            width: 50%;

            .button--secondary {
                &:hover {
                    opacity: 1
                }
            }
        }
    }

    .date-toggle-container {
        margin-top: 0px;
        padding: 16px 0;
        border-bottom: 1px solid $midGrey;
        position: relative;
        text-align: center;

        .date-toggle-button {
            width: 20px;
            height: 20px;
            margin: 0;
            display: block;
            position: absolute;
            background: url(../../images/arrow-next-black.svg) no-repeat center;
            background-size: 12px 12px;
            cursor: pointer;
            transition: opacity 0.5s ease;
            outline: none;

            &.previous {
                top: 15px;
                left: 50%;
                margin-left: -110px;
                transform: rotate(180deg);
            }

            &.next {
                top: 15px;
                right: 50%;
                margin-right: -110px;
            }

            &.not-disabled
            {    border: 0px solid pink;}

            &.disabled {
                opacity: 0.1;
                cursor: default;

                &:hover {
                    opacity: 0.1;
                }
            }

            &:hover {
                opacity: 0.8;
            }

            &:active {
                opacity: 0.5;
                transition: opacity 0.1s ease;
            }
        }
        .current-date {
            font-family: $lightBody;
            font-size: $mdFont;
            line-height: $mdFont;
        }
    }
}

.no-suitable-timeslots {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    margin: 0;
    height: 100%;
    text-align: center;
}
