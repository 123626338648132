@import '../../styles/_default_import.scss';
@import 'react-day-picker/dist/style.css';

.date-container {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: $lightGrey2;
    overflow: auto;
    max-height: 400px;
    user-select: none;
}

.date-container .rdp {
    --rdp-cell-size: 40px; /* Size of the day cells. */
    --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
    --rdp-accent-color: #{$black}; /* Accent color for the background of selected days. */
    --rdp-background-color: #{$midGrey}; /* Background color for the hovered/focused elements. */
    --rdp-accent-color-dark: #{$black}; /* Accent color for the background of selected days (to use in dark-mode). */
    --rdp-background-color-dark: #{$midGrey}; /* Background color for the hovered/focused elements (to use in dark-mode). */
    --rdp-outline: 0px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 0px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
    --rdp-selected-color: #{$white}; /* Color of selected day text */

    display: block;
    margin: 0;
    margin-bottom: 20px;
    position: relative;
    outline: none !important; // hide browser ring on focus


    * {
        outline: none !important; // hide browser ring on focus
    }

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $midGrey;
        position: absolute;
        left: 0;
        top: 28px;
    }
}

.date-container .rdp .rdp-months {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.date-container .rdp .rdp-month {
    margin: 0;
}

.rdp-head_cell {
    height: 30px;
    font-size: $smFont;
    line-height: $smFont;
    font-family: $mediumBody;
    color: $black;
    text-transform: capitalize;
}

.date-container .rdp .rdp-day {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    color: $black;
    font-size: $mdFont;
    font-family: $lightBody;
    outline: none;
    transition: all $slowDuration ease;

    &_today {
        font-family: $mediumBody;
        font-weight: normal;
    }

    &_selected {
        background-color: $black;
        color: $white;
        font-family: $mediumBody;
        font-weight: normal;
    }

    &_outside {
        background: none;
    }

    &_disabled {
        opacity: 0.1;

        &_selected {
            color: $black;
            background: none;
        }
    }
}

.date-container .rdp .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected):not(.rdp-day_outside):hover {
    background-color: $midGrey;
    color: $black !important;
    outline: none;
    opacity: 1;
}

@include respond-above(xsm) {
    .date-container
        .rdp
        .rdp-head_cell {
        font-size: $xsFont;
        line-height: $xsFont;
    }
}

.datepicker-custom-header {
    display: flex;
    justify-content: space-around;
    padding: 16px 0;
    position: relative;
    border-bottom: 1px solid $midGrey;

    &-month {
        margin: 0 50px;
    }

    &-button {
        width: 20px;
        height: 20px;
        display: block;
        background: url(../../images/arrow-next-black.svg) no-repeat center;
        background-size: 12px 12px;
        transition: opacity $slowDuration ease;
        outline: none;
        margin: 0;
        cursor: pointer;
        position: absolute;

        &:hover {
            opacity: 0.8;
        }

        &--disabled {
            opacity: 0.1;
            cursor: default;

            &:hover {
                opacity: 0.1;
            }
        }

        &--prev {
            top: 15px;
            left: 50%;
            margin-left: -110px;
            transform: rotate(180deg);
        }

        &--next {
            top: 15px;
            right: 50%;
            margin-right: -110px;
        }
    }
}

