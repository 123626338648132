//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// this file:
// @source: https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
//
// bootstrap original info
// @source: https://www.dev-tips-and-tricks.com/use-bootstrap-4-media-query-mixins

// [mck] not sure this is correct...
// so to make sure we are not doing something stupid, I will use xxl for `.container-cumulus{}` breakpoint
// source: https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints

// because of the mobile first, this is not a value `xs`

// // xs: 576px,
// // sm: 768px,
// // md: 992px,
// // lg: 1200px,
// // xxl: $max-width

// for testing purposes we created xsm... which would suggest extra small,  but is just a uniq indentifier
// xsm: 400px
// A map of breakpoints.
$breakpoints: (
  xsm: 992px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: $max-width,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
