@import '../../styles/default_import';

.confirm-header {
    margin: 0;
    padding: 30px $default-padding;
    background-color: var(--booker25-company-primary);
    color: $white;
    border-radius: 20px 0 0 0;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h2 {
        margin: 0;
    }

    .close-button {
        display: flex;
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: all 0.4s ease;
        margin-right: 1em;
        justify-content: center;
        align-items: center;

        svg g {
            fill: var(--booker25-company-primary-font-color);
        }

        &:hover {
            opacity: 0.5;
            transform: rotate(90deg);
        }
        &:active {
            opacity: 0.2;
            transition: all 0.1s ease;
        }
    }
}
