@import "../../styles/default_import";

.container-booker-processed {
    color: $black;
    background-color: $lightGrey1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow: auto;
    border-bottom: 1px solid $white;
    z-index: 3;

    h2 {
        text-align: center;
    }

    p {
        margin-bottom: $default-padding * 1.5;
        font-family: $lightBody;
        text-align: center;
    }

    .loading-state {
        width: 240px; 
        height: 240px; 
        position:relative;
        background: url(../../images/octagon.svg) no-repeat center;
        background-size: 240px 240px;
        .spinner {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -15px 0 0 -15px;
            background: url(../../images/loading-spinner.svg) no-repeat center;
            background-size: 30px 30px;
        }
    }
}

@include respond-above(xsm) {
    .container-booker-processed {
        p {
            margin-bottom: $default-padding-big;
            font-size: $mdFont;
        }
        .loading-state {
            width: 180px;
            height: 180px;
            background-size: 180px 180px;
        }
    }
}
