@import "../../styles/default_import";

.container-reservation-detail {
  background-color: $lightGrey2;
  padding-top: $default-padding;
  padding-left: $default-padding;
  padding-right: $default-padding;
  padding-bottom: $default-padding-big;
  color: $black;
  flex: 1;
  overflow: auto;
}

h2 {
  margin-bottom: 8px;

  .confirmation-icon {
      width: 13px;
      height: 11px;
      margin-left: 5px;
  }
}

.cancellation-footer {
  .buttons {
    display: flex;
    flex-flow: row;
    width: 175px;
    justify-content: space-around;
  }
}
