@import "../../styles/default_import";

.card-detail-container {
    padding: 20px 0;
    flex: 1;
    overflow: auto;
    .card-detail {
        color: $black;
        background-color: $lightGrey2;
        display: block;
        border-radius: 0 20px 0 0;
        padding: $default-padding 40px;

        .close-button {
            width: 20px;
            height: 20px;
            float: right;
            margin-right: -20px;
            background: url(../../images/close.svg) no-repeat center;
            background-size: 20px 20px;
            cursor: pointer;
            transform: rotate(0deg);
            transition: all 0.4s ease;
            &:hover {
                opacity: 0.5;
                transform: rotate(90deg);
            }
            &:active {
                opacity: 0.2;
                transition: all 0.1s ease;
            }
        }

        .card-title {
            h2 {
                margin: 0 0 5px 0;
            }
            h3 {
                margin: 0;
                font-family: $lightBody;
            }
            padding-bottom: 10px;
        }

        .capacity {
            padding-left: 12px;
            font-size: $smFont;
            letter-spacing: 0;
            line-height: 20px;
            font-family: $mediumBody;
            margin-top: $default-padding;
            margin-bottom: $default-padding - 5px;
            display: block;
            background: url(../../images/attendees-black.svg) no-repeat left 5px;
            background-size: 9px 9px;

            .price {
                font-size: $lgFont;
                letter-spacing: 0;
                line-height: 20px;
                float: right;
            }
        }

        .extra-info {
            display: flex;
            flex-direction: column;
            font-family: $lightBody;
            letter-spacing: 0;

            ul {
                margin: -5px 0 0 0;
                padding: 0;
                li {
                    list-style-type: none;
                    padding-left: 10px;
                    position: relative;
                    &:before {
                        content: "";
                        width: 3px;
                        height: 1px;
                        background-color: $black;
                        position: absolute;
                        left: 0;
                        top: 7px;
                    }
                }
            }
            .card-detail-keepinmind,
            .card-detail-features {
                box-sizing: border-box;
                padding: $default-padding;
                width: 100%;
                border-radius: 0 20px 0 0;
                background-color: $white;
            }
            .card-detail-keepinmind {
                background-color: $lightGrey1;
                border-radius: 00;
            }
        }
    }
}

@include respond-above(xsm) {
    .card-detail-container {
        .card-detail {
            padding: $default-padding 40px;
            .card-title {
                h2 {
                    margin-bottom: 2px;
                }
                padding-bottom: 10px;
            }

            .capacity {
                margin-bottom: $default-padding-half;
                font-size: $xsFont;
                line-height: 16px;
                padding-left: 12px;
                background: url(../../images/attendees-black.svg) no-repeat left 4px;
                background-size: 8px 8px;
                .price {
                    font-size: $mdFont;
                    line-height: 18px;
                }
            }

            .extra-info {
                flex-direction: row;

                .card-detail-keepinmind,
                .card-detail-features {
                    border-radius: 0;
                    width: 50%;
                }
                .card-detail-keepinmind {
                    border-radius: 0 20px 0 0;
                }
            }
        }
    }
}
