@import '../../styles/_default_import.scss';

.button-container {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  border-radius: 20px;

  h4 {
    margin: 0;
    line-height: normal;
    user-select: none;
  }

  .quantity {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
  }

  button {
    width: 20px;
    border-radius: 0px;
    background-color: $midGrey;

    &:hover {
      opacity: 0.5;
    }
  }
}