@import-normalize; /* bring in normalize.css styles */
// import 'normalize.css';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--booker25-fullscreen-background-color);
}

#booker25-widget {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

#booker25-widget.fullscreen {
  max-width: 540px;
}

// abstracts
@import "./styles/abstracts/debug";
@import "./styles/abstracts/variables";
@import "./styles/abstracts/mixins";
@import "./styles/abstracts/functions";
@import "./styles/abstracts/media-queries";

/*! #{$project-name} v#{$version} */

// vendor
// normalize?

// base
// @import "base/base";
// @import "./styles/base/minireset"; // [mck] this is a very harsh reset, we might want to rethink this one
@import "./styles/base/color";
@import "./styles/base/typography";
@import "./styles/base/cursor";
@import "./styles/base/img";
@import "./styles/base/flexboxgrid";
@import "./styles/base/spacing";

// layout
// @import "./styles/layout/footer";

// components / modules
@import "./styles/components/btn";
@import "./styles/components/media";

// pages
@import "./styles/pages/404";

// utils
@import "./styles/base/typowrapper";
