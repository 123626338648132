@import "../../styles/default_import";

.sticky-footer {
    background: $lightGrey1;
    padding: $default-padding-double $default-padding-half $default-padding-big;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid $white;
    z-index: 3;

    p {
        &.foobar {
            padding-top: $default-padding;
            color: $black;
            font-size: $mdFont;
            text-align: center;
            max-width: 220px
        }
    }
}
