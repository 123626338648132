/* Import Sequel Sans Font */
@font-face {
    font-family: "sequel_sanslight_body";
    src: url("../../fonts/sequel_sans_light_body-webfont.woff2") format("woff2"),
        url("../../fonts/sequel_sans_light_body-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sequel_sanslight_obl_body";
    src: url("../../fonts/sequel_sans_light_obl_body-webfont.woff2") format("woff2"),
        url("../../fonts/sequel_sans_light_obl_body-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sequel_sansbook_body";
    src: url("../../fonts/sequel_sans_book_body-webfont.woff2") format("woff2"),
        url("../../fonts/sequel_sans_book_body-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sequel_sansbook_obl_body";
    src: url("../../fonts/sequel_sans_book_obl_body-webfont.woff2") format("woff2"),
        url("../../fonts/sequel_sans_book_obl_body-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sequel_sansmedium_body";
    src: url("../../fonts/sequel_sans_medium_body-webfont.woff2") format("woff2"),
        url("../../fonts/sequel_sans_medium_body-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sequel_sansmedium_obl_body";
    src: url("../../fonts/sequel_sans_medium_obl_body-webfont.woff2") format("woff2"),
        url("../../fonts/sequel_sans_medium_obl_body-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

// Font Families
$lightBody: "sequel_sanslight_body", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$bookBody: "sequel_sansbook_body", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$mediumBody: "sequel_sansmedium_body", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

// Font Sizes
$xxsFont: 8px;
$xsFont: 9px;
$smFont: 10px;
$mdFont: 12px;
$lgFont: 14px;
$xlFont: 16px;

body {
    font-family: $bookBody;
    font-size: $mdFont;
    line-height: 18px;
    color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    margin: 0 0 20px 0;
    font-family: $mediumBody;
    font-weight: normal;
}

h2 {
    font-size: $xlFont;
    line-height: 20px;
    margin: 0 0 10px 0;
    font-family: $mediumBody;
    font-weight: normal;
}

h3 {
    font-size: $mdFont;
    line-height: 16px;
    margin: 0 0 15px 0;
    font-family: $mediumBody;
    font-weight: normal;
}

h4 {
    font-family: $mediumBody;
    font-weight: normal;
}

p {
    margin: 0 0 20px 0;
    font-family: $lightBody;
    font-weight: normal;
}

@include respond-above(xsm) {
    body {
        font-size: $xsFont;
        line-height: 14px;
    }
    h2 {
        font-size: $lgFont;
        line-height: 17px;
    }
    h3 {
        font-size: $xsFont;
        line-height: 16px;
        margin-bottom: 10px;
    }
}
