@import "../../styles/_default_import.scss";

.locations-container {
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: $lightGrey2;
    overflow: hidden;

    .loading-state {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .spinner {
            width: 30px;
            height: 30px;
            margin-bottom: 5px;
            background: url(../../images/loading-spinner.svg) no-repeat center;
            background-size: 30px 30px;
        }

        .loading-text
        {    margin: 0;}
    }

    .locations-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        overflow: auto;

        li {
            padding: 14px 18px;
            font-family: $bookBody;
            font-size: $mdFont;
            line-height: $mdFont;
            border-bottom: 1px solid $midGrey;
            cursor: pointer;
            transition: all 0.4s ease;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &:hover
                {background-color: $midGrey;}


            &:active {
                background-color: darken($midGrey, 5%);
                transition: all 0.1s ease;
            }

            .checkbox {
                width: 15px;
                height: 15px;
                display: block;
                margin-right: 10px;
            }

            &.is-active  {
                font-weight: bold;

                .checkbox {
                    background-image: url('../../images/confirmation-black.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            &:last-of-type
            {    border-bottom: none;}

            &.sub-category {
                font-size: 1.1em;
                font-weight: bolder;
                background-color: darken($midGrey, 10%);

                &:hover
                    {cursor: unset;}
            }
        }
    }
}