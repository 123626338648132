@import "../../styles/_default_import.scss";

.global-footer {
    background: $lightGrey1;
    padding: 15px $default-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.15);
    z-index: 2;
    .email-link {
        font-family: $mediumBody;
        font-size: $mdFont;
        line-height: $mdFont;
        color: $black;
        text-decoration: none;
    }
    .booker25-logo {
        width: 70px;
        height: 14px;
        background: url(../../images/gm-logo-black.svg) no-repeat center;
        background-size: 70px 14px;
    }
}

@include respond-above(xsm) {
    .global-footer {
        padding: 10px $default-padding;
        .email-link {
            font-size: $smFont;
            line-height: $smFont;
        }
        .booker25-logo {
            width: 60px;
            height: 12px;
            background-size: 60px 12px;
        }
    }
}
