.loading-state {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .spinner {
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
        background: url(./images/loading-spinner.svg) no-repeat center;
        background-size: 30px 30px;
    }
}

.widget-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}