@import '../../styles/default_import.scss';

.input-field {
  display: block;
  margin-bottom: 20px;

  .input-label {
    font-size: $mdFont;
    display: block;
    margin-bottom: 5px;
  }

  &__flex {
    display: flex;
  }

  label {
    margin-right: 10px;
  }

  input[type='text'],
  input[type='email'],
  input[type='number'] {
    height: 40px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    border-radius: 20px;
    background-color: $white;
    font-family: $mediumBody;
    font-size: $mdFont;
    line-height: $mdFont;
    color: $black;
    border: 1px solid $white;
    outline: none;
    resize: none;
    transition: all 0.4s ease;
    &::placeholder {
      font-family: $lightBody;
      color: $darkGrey;
      opacity: 1;
    }
    &:hover {
      border: 1px solid lighten($black, 80%);
    }
    &:focus {
      border: 1px solid $black;
    }
  }

  /* Custom Checkbox */
  &.checkbox-container {
    display: block;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .input-label {
      margin: 0;
      line-height: 22px;
      &.link {
        text-decoration: underline;
      }
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 2px;
      background-color: $midGrey;
      transition: all 0.4s ease;
      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        opacity: 0;
        display: none;
        transition: all 0.2s ease;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url(../../images/confirmation.svg) no-repeat center;
        background-size: 10px 10px;
      }
    }

    &:hover input ~ .checkmark {
      background-color: $darkGrey;
    }

    input:checked ~ .checkmark {
      background-color: $black;
    }

    input:checked ~ .checkmark:after {
      display: block;
      opacity: 1;
    }
  }

  .error-message {
    display: none;
    padding-top: 10px;
    color: $error;
    font-family: $lightBody;
    font-size: $xsFont;
    line-height: $xsFont;
    text-align: right;
  }
  &.has-error {
    input[type='text'],
    input[type='email'],
    input[type='number'] {
      border-color: $error;
    }
    .error-message {
      display: block;
    }
  }
}

textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: $white;
  font-family: $lightBody;
  font-size: $mdFont;
  line-height: 18px;
  color: $black;
  border: 1px solid $white;
  outline: none;
  resize: none;
  transition: all 0.4s ease;
  &::placeholder {
    color: $darkGrey;
    opacity: 1;
  }
  &:hover {
    border: 1px solid lighten($black, 80%);
  }
  &:focus {
    border: 1px solid $black;
  }
}

@include respond-above(xsm) {
  label {
    .input-label {
      font-size: $xsFont;
      margin-bottom: 2px;
    }
    &.checkbox-container {
      margin-bottom: 10px;
      padding-left: 20px;
      .input-label {
        line-height: 13px;
      }
      .checkmark {
        height: 12px;
        width: 12px;
      }
      .checkmark:after {
        width: 12px;
        height: 12px;
        background-size: 8px 8px;
      }
    }
  }

  textarea {
    font-size: $xsFont;
    line-height: 14px;
  }
}

.hidden {
  display: none;
}