@import "../../styles/default_import";

.service-detail {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 10px;
    padding-right: 10px;
    color: $black;
    background-color: $lightGrey2;
    border-radius: 0 20px 0 0;
    height: 100px;
  
    .service-img {
      width: 80px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .service-title {
      flex: 1;
      margin: 10px 10px;
      overflow-y: hidden;
      h2 {
        font-size: $mdFont;
        margin: 0 0 5px 0;
      }
      h3 {
        margin: 0;
        font-size: $smFont;
        font-family: $lightBody;
      }
    }

    .service-action {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      p {
        margin: 0;
      }
    }
}