@import "../../styles/default_import";

.company-header {
    position: relative;
    height: 60px;
    box-sizing: border-box;
    padding: 20px;
    background-color: var(--booker25-company-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.2);
    z-index: 2;
    .close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: all 0.4s ease;

        svg g {
            fill: var(--booker25-company-primary-font-color);
        }

        &:hover {
            opacity: 0.5;
            transform: rotate(90deg);
        }
        &:active {
            opacity: 0.2;
            transition: all 0.1s ease;
        }
    }

    .companyLogo {
        height: 20px;
        font-size: 1.3em;
        font-weight: bold;
        color: var(--booker25-company-primary-font-color);
    }
    .header-spacer {
        width: 20px;
        height: 20px;
    }

    .lang-select {
        position: absolute;
        right: 20px;
        display: flex;



        .divider {
            border-right: 1px solid var(--booker25-company-primary-font-color);
            margin: 0 7px;
        }

        button {
            border: none;
            background: transparent;
            padding: 0;
            border-bottom: 1px solid transparent;
            cursor: pointer;
            color: var(--booker25-company-primary-font-color);

            &:focus {
                outline: none;
            }
        }

        .active {
            font-weight: bold;
        }

        select {
            background: transparent;
            border: none;
            color: var(--booker25-company-primary-font-color);
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }
}
