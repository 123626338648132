@import "../../styles/default_import";
@import "../../styles/forms";

.form-container {
    flex: 1;
    overflow-y: scroll;
    .client-contact-container {
        padding: $default-padding-big-half;
        background-color: $lightGrey2;
        flex: 1;
        .client-details {
            margin-bottom: 40px;
        }
    }
}

@include respond-above(xsm) {
    .client-contact-container {
        padding-left: $default-padding-big;
        padding-right: $default-padding-big;
    }
}

.details_form {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.hidden-section {
    display: none;
}
