@import "../../styles/_default_import.scss";

.filter-sticky-footer {
    .button {
        margin-bottom: 30px;
        &:disabled,
        &:disabled:hover {
            opacity: 1;
            color: lighten($black, 80%);
            cursor: default;
        }
    }
    .thank-you-text {
        max-height: 100px;
        max-width: 280px;
        margin: 0;
        opacity: 1;
        text-align: center;
        font-size: $mdFont;
        line-height: 20px;
        font-family: $lightBody;
        span {
            font-family: $mediumBody;
        }
    }
    &.hide-text {
        height: auto;
        max-height: 100px;
        .button {
            margin-bottom: 0;
        }
        .thank-you-text {
            max-height: 0;
            opacity: 0;
        }
    }
}
