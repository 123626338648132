.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;

    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    background-color: transparent;
    border: 1px solid transparent;

    white-space: nowrap;

    font-family: $mediumBody;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;

    opacity: 1;

    border-radius: 100px;
    padding: 10px 20px;

    &:hover {
        opacity: 0.8;
    }

    transition: color $mediumDuration ease-in-out, background-color $mediumDuration ease-in-out,
        border-color $mediumDuration ease-in-out, opacity $mediumDuration ease-in-out,
        box-shadow $mediumDuration ease-in-out;
}
.btn-company,
.btn-primary {
    color: $white;
    background-color: var(--booker25-company-primary);
    border-color: var(--booker25-company-primary);

    &:hover {
        filter: brightness(90%);
    }
}

.btn-close {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform $fastDuration linear;
    &:hover {
        transform: rotate(90deg);
    }
}

.btn-white {
    color: $black;
    background-color: $white;
    border-color: $white;
}
.btn-black {
    color: $white;
    background-color: $black;
    border-color: $black;
}
.btn-midgray,
.btn-gray {
    color: $black;
    background-color: $midGrey;
    border-color: $midGrey;

    border-radius: 100px;
    padding: 5px 10px;

    font-size: 9px;
    letter-spacing: 0;
    line-height: 11px;

    &:hover {
        color: $white;
        background-color: $black;
        border-color: $black;
    }
}
.btn-debug {
    color: $black;
    background-color: #39ff14;
    border-color: #39ff14;

    font-size: 12px;
    letter-spacing: 0;
    line-height: 11px;

    border-radius: 100px;
    padding: 5px 10px 5px 30px;

    background-image: url("../../images/skull.svg");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 10px center;

    &:hover {
        background-color: darken(#39ff14, 10);
        border-color: darken(#39ff14, 10);
    }
    &.disabled,
    &:disabled {
        opacity: 0.65;
        color: $black;
        background-color: #39ff14;
        border-color: #39ff14;
    }
}

.btn-next {
    // padding: 5px 10px 5px 30px;
    padding-right: 50px;
    padding-left: 30px;
    background-image: url("../../images/arrow-next.svg");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 90% center;
    transition: background-position $fastDuration ease-out;
    &:hover {
        opacity: 0.8;
        background-position: 92% center;
    }
    &.disabled,
    &:disabled {
        opacity: 0.65;
    }
}
