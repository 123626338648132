@import "../../styles/_default_import.scss";

.generic-sticky-footer {
    height: auto;
    max-height: 200px;
    background: $lightGrey1;
    padding: 40px 10px 32px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $white;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.15);
    z-index: 3;
    transition: all 0.5s ease;
}
