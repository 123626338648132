@import '../../styles/default_import';

.card-summary {
  border-radius: 0 20px 0 0;
  background-color: $white;
  padding: $default-padding;
  padding-bottom: 0;
  margin-bottom: $default-padding;
  position: relative;

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  h2 {
    margin-top: 2px;
    margin-bottom: 0;
  }

  h3 {
    color: $darkGrey;
    font-family: $lightBody;
  }

  h4 {
    color: $black;
    font-family: $bookBody;
    margin-bottom: 10px;
    margin-top: 2px;
  }

  ul {
    margin: 0 0 $default-padding - 4px 0;
    padding: 0;
    margin-bottom: 10px;

    li {
      font-family: $lightBody;
      list-style-type: none;
      padding-left: 10px;
      position: relative;

      &:before {
        content: "";
        width: 3px;
        height: 1px;
        background-color: $black;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
      }
    }
  }

  .img-fit-cover {
    float: right;
    margin: -20px -20px 0 5px;
    border-radius: 0 20px 0 0;
    height: 130px;
    width: 130px;
  }
}
