// https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9
// /*
// This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
// It will generate several classes such as:
// .mr-10 which gives margin-right 10 pixels.
// .mr-15 gives MARGIN to the RIGHT 15 pixels.
// .mt-15 gives MARGIN to the TOP 15 pixels and so on.
// .pb-5 gives PADDING to the BOTTOM of 5 pixels
// .pl-40 gives PADDING to the LEFT of 40 pixels
// The first letter is "m" or "p" for MARGIN or PADDING
// Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
// Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
// */

$spaceamounts: (
    0,
    5,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    60,
    70,
    75,
    80,
    90,
    100,
    150,
    200
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right, all); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        @if $side == "all" {
            .m-#{$space} {
                margin: #{$space}px;
            }

            .p-#{$space} {
                padding: #{$space}px;
            }
        } @else {
            .m#{str-slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space}px;
            }

            .p#{str-slice($side, 0, 1)}-#{$space} {
                padding-#{$side}: #{$space}px;
            }
        }
    }
}

@each $space in $spaceamounts {
    .m-#{$space} {
        margin: #{$space}px !important;
    }
    .mx-#{$space} {
        margin-right: #{$space}px;
        margin-left: #{$space}px;
    }
    .my-#{$space} {
        margin-top: #{$space}px;
        margin-bottom: #{$space}px;
    }
    .p-#{$space} {
        padding: #{$space}px !important;
    }
    .px-#{$space} {
        padding-right: #{$space}px;
        padding-left: #{$space}px;
    }
    .py-#{$space} {
        padding-top: #{$space}px;
        padding-bottom: #{$space}px;
    }
}
