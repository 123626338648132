@import "../../styles/default_import";

.card-summary-price {
    background-color: $white;
    padding: $default-padding;
    margin-bottom: $default-padding;
    h3 {
        margin-bottom: 10px;
    }
    .total-overview {
        margin: 0 auto;
        padding-left: calc($default-padding / 2);
    }

    .overview-row {
        margin: 0;
        padding: 6px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $darkGrey;
        border-bottom: 1px solid $midGrey;

        &.title {
            padding-bottom: 10px;
            color: $black;
            font-weight: 600;

            .overview-column {
                &:first-of-type {
                    font-family: $lightBody;
                }
            }
            .price-container {
                font-family: $mediumBody;
            }
        }

        &:last-of-type {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .overview-column {
        margin: 0;
        padding: 0;
        font-family: $bookBody;
        .price-container {
            font-family: $bookBody;
        }
    }
}

@include respond-above(xsm) {
    .card-summary-price {
        h3 {
            margin-bottom: 0;
        }
        .overview-row {
            padding: 3px 0;
            &.title {
                padding-bottom: 5px;
            }
        }
    }
}
