// DEFAULT OPTIONS
$react-root-id: "#booker25-widget";
$project-name: "booker25-widget";
$version: "0.0.1";

// defaults
$default-padding: 20px;
$default-padding-half: calc($default-padding / 2); // 10px
$default-padding-double: calc($default-padding * 2); // 40px
$default-padding-big: 60px;
$default-padding-big-half: calc($default-padding-big / 2); // 30px

// grid padding
$grid-padding: 20px;

// max size container
$breakpoint-xxl: 1920px;
$max-width: $breakpoint-xxl;

// Durations
$fastDuration: 0.1s !default;
$mediumDuration: 0.3s !default;
$slowDuration: 0.5s !default;
$slowerDuration: 1s !default;
