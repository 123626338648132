@import "../../styles/default_import";

.container-checkout-overview {
    background-color: $lightGrey2;
    padding-top: $default-padding;
    padding-left: $default-padding;
    padding-right: $default-padding;
    padding-bottom: $default-padding-big;
    color: $black;
    flex: 1;
    overflow: auto;

    .btn-debug {
        float: right;
    }
}

@include respond-above(xsm) {
    .container-checkout-overview {
        padding-left: $default-padding-big;
        padding-right: $default-padding-big;
        .card-summary {
            margin-bottom: $default-padding-half;
            h3 {
                margin-bottom: 2px;
            }
            .img-fit-cover {
                width: 115px;
                height: 115px;
            }
        }
    }
}
