@import "../../styles/_default_import.scss";

.space-header-container {
    margin: 0;
    padding: 15px $default-padding;
    background-color: var(--booker25-company-primary);
    color: $white;
    border-radius: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    .title {
        padding-left: $default-padding;
        h2 {
            margin: 0 0 5px 0;
        }
        h3 {
            margin: 0 0 -3px 0;
            font-size: $mdFont;
            line-height: 16px;
            font-family: $bookBody;
        }
    }
    .back-button {
        font-size: 0;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        display: block;
        height: 20px;
        width: 20px;
        background: url(../../images/back-white.svg) no-repeat center;
        background-size: 20px 20px;
        cursor: pointer;
        transition: all 0.4s ease;
        &:hover {
            opacity: 0.5;
        }
        &:active {
            opacity: 0.2;
            transition: all 0.1s ease;
        }
    }
}

@include respond-above(xsm) {
    .space-header-container {
        padding: 13px $default-padding;
        .title {
            h3 {
                margin: 0;
                font-size: $xsFont;
                line-height: 12px;
            }
        }
    }
}
