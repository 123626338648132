@import '../../styles/_default_import.scss';

.accordion-button {
    font-family: $mediumBody;
    color: var(--booker25-company-primary-font-color);
    background-color: var(--booker25-company-primary);
    font-size: $lgFont;
    line-height: $lgFont;
    padding: 13px 15px;
    width: 100%;
    border: 0;
    margin: 0 0 1px 0;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    transition: all 0.4s ease;
    position: relative;

    svg {
        width: 15px;
        height: 15px;
        transition: all 0.2s ease;
    }

    .icon-down svg {
        transform: rotate(-180deg);
    }

    &:hover {
        filter: brightness(115%);
    }

    &:active {
        filter: brightness(80%);
        transition: all 0.1s ease;
    }

    &:after {
        content: '';
        width: 14px;
        height: 14px;
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -7px;
        background-color: transparent;
        transition: all 0.2s ease;
    }

    &:disabled {
        opacity: 0.4;
        background-color: var(--booker25-company-primary);
        cursor: default;
    }

    &--hidden {
        display: none;
    }
}

.accordion-container {
    opacity: 1;
    // This cannot be a dynamic value (like 100%), transition will not work
    // 480px is the max height of the TimeSlotPicker
    max-height: 480px;
    transition: all 0.5s ease;
    overflow: hidden;

    &--hidden {
        max-height: 0px;
        opacity: 0;
        overflow: none;
    }
}

@include respond-above(xsm) {
    .accordion-button {
        font-size: $mdFont;
        line-height: $mdFont;
        padding-top: 14px;
        padding-bottom: 14px;
    }
}
