@import "../../styles/default_import";

.container-space-selection {
    flex: 1;
    overflow: auto;
    padding: 20px 20px 10px 20px;
}

@include respond-above(xsm) {
    .container-space-selection {
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        .card-wrapper {
            width: 50%;
            box-sizing: border-box;
            &:nth-child(odd) {
                padding-right: 5px;
            }

            &:nth-child(even) {
                padding-left: 5px;
            }
        }
    }
}
