@import "../../styles/default_import";

.loading-state {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spinner {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
      background: url(../../images/loading-spinner.svg) no-repeat center;
      background-size: 30px 30px;
  }
}

.service-list-container {
  padding: 15px 20px;
  overflow: auto;
  flex: 1;
  .bottom-spacing {
    padding-bottom: 5px;
    h3 {
      margin: 5px 0;
    }
  }
}
