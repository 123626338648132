@import '../../styles/_default_import.scss';

button {
  font-family: $mediumBody;
  border: 0;
  margin: 0;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  transition: all 0.4s ease;
  background-position: left 15px center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.2;
    transition: all 0.1s ease;
  }

  &.button--primary {
    color: $white;
    background-color: var(--booker25-company-primary);
    &:disabled {
      opacity: 0.1;
      background-color: var(--booker25-company-primary);
    }
  }

  &.button--secondary {
    background-color: $black;
    color: $white;
    &:disabled {
      opacity: 0.1;
      background-color: $black;
    }
  }

  &.button--white {
    background-color: $white;
    color: $black;
    &:hover {
      background-color: $midGrey;
    }
    &:disabled {
      background-color: $white;
    }
  }

  &.button--border {
    border: 2px solid $black;
  }

  &.button--grey {
    background-color: $midGrey;
    color: $black;
    &:disabled {
      opacity: 0.1;
      background-color: $midGrey;
    }
  }

  &.button--small {
    font-size: $smFont;
    line-height: 12px;
    padding: 5px 18px;
  }

  &.button--large {
    font-size: $mdFont;
    line-height: 16px;
    padding: 12px 20px;
  }

  &.full-width {
    width: 100%;
    justify-content: center;
  }

  &.text-link {
    padding: 0 !important;
    border: none !important;
    background: none !important;
    color: var(--booker25-company-primary) !important;
    box-shadow: none !important;
  }

  .icon-container {
    width: 14px;
    height: 14px;
    background-repeat: 0;
    background-position: center;
    background-size: 14px 14px;
  }

  &.icon-left {
    padding-left: 22px;
    padding-right: 22px;
    .icon-container {
      margin-right: 12px;
    }
  }

  &.icon-right {
    padding-left: 22px;
    padding-right: 22px;
    background-position: right 15px center;
    .icon-container {
      margin-left: 12px;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    cursor: default;
  }

  &.inactive-button {
    font-size: 10px;
    font-weight: light;
    padding: 5px 10px;
    color: $darkGrey;
    background-color: $lightGrey1;
    border: 1px solid #e6e6e6;
    &:hover {
        opacity: 1;
    }
  }
}

@include respond-above(xsm) {
  .button {
    &.button--small {
      font-size: $xsFont;
      line-height: 10px;
      padding: 5px 18px;
    }
  }
}
